import * as React from "react";
import { Link } from "gatsby";
import {fadeInUpStyle,fadeInLeftStyle,fadeInRigthStyle} from "../styles/style";
import { StaticImage } from "gatsby-plugin-image";

const Footer = (props) => {
  return (
    <footer className="footer pt-60">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div
              className="footer-widget mb-60 wow fadeInLeft"
              data-wow-delay=".2s"
              style={fadeInLeftStyle}
            >
              <p className="mb-20 footer-desc">
              Da più di 30 Anni, Ci occupiamo di pulizie di Uffici, Condomini, Negozi, Palestre 
              e Studi Medici a Milano e Provincia. 
              MANUTENPUL Garantisce Serietà, Professionalità e rapidità!
              </p>
              1990 - {(new Date().getFullYear())}    
            </div>
          </div>
          <div className="col-xl-2 offset-xl-1 col-lg-2 col-md-6">
            <div
              className="footer-widget mb-20 wow fadeInUp"
              data-wow-delay=".4s"
              style={fadeInUpStyle}
            >
              <p className="false-h4">&nbsp;&nbsp;Link Utili</p>
              <ul className="footer-links">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/servizio/sanificazione">Sanificazione</Link>
                </li>
                <li>
                  <Link to="/servizio/disinfestazioni">Disinfestazioni</Link>
                </li>
                <li>
                  <Link to="/contact" className="page-scroll">Contatti</Link>
                </li>
                <li>
                  <a className="page-scroll" href="https://www.manutenpul.it">
                      Area Riservata
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6">
            <div
              className="footer-widget mb-20 wow fadeInUp"
              data-wow-delay=".6s"
              style={fadeInUpStyle}
            >
              <p className="false-h4">&nbsp;&nbsp;I Ns. Servizi</p>
              <ul className="footer-links">
                <li>
                  <Link to="/servizio/pulizia-condomini">Pulizie Condomini</Link>
                </li>
                <li>
                  <Link to="/servizio/pulizia-condomini">Pulizie Uffici</Link>
                </li>
                <li>
                  <Link to="/servizio/pulizie-industriali">Pulizie Industriali</Link>
                </li>
                <li>
                  <Link to="/servizio/pulizia-appartamenti">Pulizie Appartamenti</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6">
            <div
              className="footer-widget mb-20 wow fadeInRight"
              data-wow-delay=".8s"
              style={fadeInRigthStyle}
            >
              <p className="false-h4">&nbsp;&nbsp;MANUTENPUL SRLS.</p>
              <ul className="footer-contact">
                <li>
                  <p>Sede Legale: Viale Pisa, 6 - 20146 Milano (MI)</p>
                </li>
                <li>
                  <p>Sede Operativa: Via R. Sanzio, 18 - 20090 Cesano Boscone (MI)</p>
                </li>
                <li>
                  <p>Sede Operativa: Via XX Settembre 137 - 20025 Legnano (MI)</p>
                </li>
                <li>                  
                  <p>Telf.: <a style={{"color":"white"}} href="tel:+39.02.31055156">+39.02.31055156</a></p>
                </li>
                <li>                  
                  <p>Cel.: <a style={{"color":"white"}} href="tel:+39.3917415330">+39 3917415330 </a></p>
                </li>
                <li>
                  <p>Email: <a style={{"color":"white"}} href="mailto:info@manutenpul.it">info@manutenpul.it</a></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="footer-social-links">
                <ul className="d-flex">
                  <li>
                    <a href="https://www.facebook.com/Impresa-di-Pulizie-e-Servizi-MANUTENPUL-SRLs-304909939904947">
                      <StaticImage src="../images/facebook-icon.png" width={35} height={35} alt="facebook-profile"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/manutenpul.it/">
                      <StaticImage src="../images/instagram.png" width={35} height={35} alt="instragram-profile"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/manutenpul-srls/mycompany/">
                      <StaticImage src="../images/linkedin.png" width={35} height={35} alt="linkedin-profile"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://api.whatsapp.com/send/?phone=+393917417882&text=Sono+interessato+a+un+servizio&app_absent=0">
                      <StaticImage src="../images/whatsapp-icon.png" width={35} height={35} alt="whatsapp-profile"/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <p
                className="wow fadeInUp"
                data-wow-delay=".3s"
                style={fadeInUpStyle}
              > 
                Template Designed by{" "}
                <Link to="#" rel="nofollow">
                  IncaMaya Devs © {(new Date().getFullYear())}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;