export const fadeInDownStyle = {
  visibility: "visible",
  animationDelay: "0.2s",
  animationName: "fadeInDown",
};

export const fadeInUpStyle = {
  visibility: "visible",
  animationDelay: "0.4s",
  animationName: "fadeInUp",
};

export const fadeInLeftStyle = {
  visibility: "visible",
  animationDelay: "0.4s",
  animationName: "fadeInLeft",
};

export const fadeInRigthStyle = {
  visibility: "visible",
  animationDelay: "0.5s",
  animationName: "fadeInRight",
};

export const list_style = {
  listStyleType: "none",
};

export const pageStyles = {
  color: "#232129",
  // background: 'linear-gradient(70deg, #2e88a2, #FFF)',
  background:"#f1f1f1",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

export const cityDiv = {
  marginBottom: "3em",
};

export const mapDiv = {
  textAlign: "center",
};

export const mapSize = {
  maxWidth: "600px",
};
