import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
// import { graphql, Link } from "gatsby"
import { Link } from "gatsby";

const titleStyle = {
  fontSize: "25px",
  fontWeight: "600",
  color: "#17a2b5",
};
const titleStyle2 = {
  fontSize: "25px",
  fontWeight: "600",
  color: "#000",
  display: "inline",
};

const navbarArea = {
  position: "fixed",
  backgroundColor: "#FFF",
  top: 0,
  left: 0,
  height:"130px",
  width: "100%",
  zIndex: 99,
  transition: "all .3s ease-out 0s",
  padding: 0,
};

const lineStyle = {
  background: "linear-gradient(to left, #2e88a2 0%, #88ddf5 50.39%, #3763eb 100%)",
  height:"25px",
  color:"#FFF",
  textAlign:"center",
}

const linkStyle = {
  color:"#FFF",
}

const  NavBar = () => {
  return (
    <div style={navbarArea}>
      <div style={lineStyle}><Link style={linkStyle} to="/contact">Richiedi Preventivo GRATUITO</Link></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand" rel="icon" href="https://www.manutenpul.it/">
                <StaticImage src="../images/logo5.webp" alt="Logo" width={75} height={75}/>
              </a>
                <Link to="/" >
                <div style={titleStyle} >
                    MANUTEN<span style={titleStyle2}>PUL</span>
                    </div>
                </Link>                 
              
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
              >
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse sub-menu-bar"
                id="navbarSupportedContent"
              >
                <ul id="nav" className="navbar-nav ms-auto">
                <li className="nav-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="nav-item">
                    <p
                      className="page-scroll dd-menu collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#submenu-1-1"
                    >
                      Servizi Di Pulizia
                      <span className="material-icons">expand_more</span>
                    </p>
                    <ul className="sub-menu collapse" id="submenu-1-1">
                      <li className="nav-item">
                        <Link to="/servizio/pulizia-uffici">Pulizie Uffici</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/pulizia-appartamenti">Pulizie Appartamenti</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/pulizia-condomini">Pulizie Condomini a Milano</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/pulizie-industriali">Pulizie Industriali</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/lavaggio-vetrate-tapparelle-insegne">Lavaggio Vetrate, Insegne, Tapparelle, Veneziane</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/lucidatura-levigatura-mamo-e-parquet">Lucidatura E Levigatura Marmo e Parquet</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/trattamento-pavimentazione-e-lavaggio-moquette">Trattamento Pavimentazione e Lavaggio Moquette</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/spazza-camino-e-pulizia-canna-fumaria">Spazzacamino e Pulizia Canna Fumaria</Link>
                      </li>
                    </ul>
                  </li>                
                  <li className="nav-item">
                    <Link to="/servizio/sanificazione">Sanificazione</Link>
                  </li>
                  <li className="nav-item">
                    <p
                      className="page-scroll dd-menu collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#submenu-2-2"
                      
                    >
                      Altri Servizi
                      <span className="material-icons">expand_more</span>
                    </p>
                    <ul className="sub-menu collapse" id="submenu-2-2">
                      <li className="nav-item">
                        <Link to="/servizio/disinfestazioni">Disinfestazioni</Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link to="/servizio/disinfestazioni">Derattizzazione</Link>
                      </li> */}
                      <li className="nav-item">
                        <Link to="/servizio/giardinaggio-trattamento-verde" className="page-scroll">
                          Giardinaggio / Cura del Verde
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/imbiancatura" className="page-scroll">
                          Fachinaggio e Trasloco
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/imbiancatura" className="page-scroll">
                          Sgombero Locali
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/ristrutturazione" className="page-scroll">
                          Ristrutturazioni 
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/imbiancatura" className="page-scroll">
                          Imbiancatura e Verniciatura
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/portierato" className="page-scroll">
                         Reception & Portierato
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/servizio/rotazioni-sacchi-e-raccolta-differenziata" className="page-scroll">
                          Rotazione Sacchi
                        </Link>
                      </li>
                    </ul>
                  </li>


                  <li className="nav-item">
                    <p
                      className="page-scroll dd-menu collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#submenu-2-2"
                      
                    >
                      Certificazioni
                      <span className="material-icons">expand_more</span>
                    </p>
                    <ul className="sub-menu collapse" id="submenu-2-2">
                      <li className="nav-item">
                        <Link to="/certificazioni/iso-9001">ISO 9001</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/certificazioni/iso-14001">ISO 14001</Link>
                      </li>
                    </ul>
                  </li>
                  
                  <li className="nav-item">
                    <p
                      className="page-scroll dd-menu collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#submenu-4-4"
                    >
                      Contatti
                      <span className="material-icons">expand_more</span>
                    </p>
                    <ul className="sub-menu collapse" id="submenu-4-4">
                      <li className="nav-item">
                        <Link to="/contact">PREVENTIVI / CONTATTI</Link>
                      </li>
                      {/*<li className="nav-item">
                        <Link to="#" className="page-scroll">
                          Lavora con Noi
                        </Link>
                      </li>/}
                      {/* <li className="nav-item">
                        <Link to="#" className="page-scroll">
                          Certificazioni / Garanzie
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link to="#" className="page-scroll">
                          Domande Frequenti
                        </Link>
                      </li>
                      <li className="nav-item">
                       <a href="https://www.manutenpul.it" className="page-scroll">
                        AREA RISERVATA
                        </a>
                      </li>
                     
                    </ul>
                  </li>
                  
                
                  <li className="nav-item">
                    <Link to="/blog" className="page-scroll">
                        Blog
                      </Link>
                  </li>

                 {/* <li className="nav-item">
                    <a
                      className="page-scroll dd-menu collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#submenu-3-3"
                    >
                      Copertura
                      <span className="material-icons">expand_more</span>
                    </a>
                    <ul className="sub-menu collapse" id="submenu-3-3">
                      <li className="nav-item">
                        <Link to="/c/milano/impresa-pulizie-Milano" className="page-scroll">
                          Milano
                        </Link>
                      </li>
                        <li className="nav-item">
                          <Link to="/c/impresa-pulizie-Cesano-Boscone">
                            Cesano Boscone
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/c/impresa-pulizie-Legnano">
                            Legnano
                          </Link>
                        </li>
                      <li className="nav-item">
                        <Link to="/c/impresa-pulizie-Venezia" className="page-scroll">
                          Monza e Brianza
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/c/impresa-pulizie-Venezia" className="page-scroll">
                          Bergamo
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/c/impresa-pulizie-Venezia" className="page-scroll">
                          Como
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/c/impresa-pulizie-Venezia" className="page-scroll">
                          Varese
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/c/impresa-pulizie-Venezia" className="page-scroll">
                          Venezia
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/c/impresa-pulizie-Firenze" className="page-scroll">
                          Firenze
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/c/impresa-pulizie-Genova" className="page-scroll">
                          Genova
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/c/impresa-pulizie-Ravenna" className="page-scroll">
                          Ravenna
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/c/impresa-pulizie-Roma" className="page-scroll">
                          Roma
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/c/impresa-pulizie-Roma" className="page-scroll">
                          Torino
                        </Link>
                      </li>
                    </ul>
                  </li>
                    */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
